<template>
  <div>
    <h3 ref="intro">
      Percentage
    </h3>
    <p>
      Percent is often used to express the number of things in a group relative to total size of the group.
      <br><br>
      For example, consider a class of 60 of students that has 45 girls and 15 boys.
      <br><br> How can we express the numbers of girls relative to total number of students in the class?
      One can use percentage. Percent essently means <i> out of 100</i>.
      Essentially we ask &mdash; if 45 girls out of 60 students, then how many girls out of 100 students?
      <br><br>
      We can find the answer by solving the following equation:
      $$\frac{45}{60} = \frac{x}{100}$$
      Upon moving \(100\) to the left hand side of the equation, we obtain the value of \(x\) as
      $$x = \frac{45}{60} \times 100 = 75$$
      Thus, we can say 75% of the students in the class are girls.
      <br><br>
    </p>
    <h3 ref="intro">
      Finding Percentage
    </h3>
    <p>
      To calculate the percentage of x out of y, use the following formula:
      $$ \% \text{ value} = \frac{x}{y} \times 100$$
    </p>
    <h3 ref="pg">
      MagicGraph | Percentage to Numbers
    </h3>
    <p>
      Shown in the MagicGraph is a collection of 50 empty boxes. Tap on a box to select/deselect it.
      Selected box is shown in red.
      The goal is to select boxes that make up the given percentage of the total collection.
    </p>
    <br>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Percentage2',
   created () {
  //  await this.$store.dispatch('navigation/getData');
    this.$store.commit('navigation/resetState');
    let title = 'Numbers to Percentage';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'Parallel Lines', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Transversal', img:'/assets/number-2.svg', action: () => this.goto('trans')},
      {title: 'Pair of Angles', img:'/assets/number-3.svg', action: () => this.goto('angles')},
      {title: 'MagicGraph',img:'/assets/touch.svg', action: () => this.goto('pg')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Percentage',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
